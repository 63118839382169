<template>
  <div style="padding: 20px">
    <el-form ref="form" :model="FindForm" label-width="80px" style="float: left">
      <el-form-item label="用户名：">
        <el-input style="width: 300px" @blur="noInp" placeholder="请输入用户的邮箱" v-model="FindForm.userName"></el-input>
        <el-button style="margin-left: 20px" @click="findSure">查询</el-button>
        <el-button @click="allUser">添加用户</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-bottom: 10px" v-loading="login">
      <el-table :data="TableData" style="width: 100%" :row-style="{height: '60px'}">
        <el-table-column sortable label="用户ID" width="90px" prop="Id"/>
        <el-table-column label="注册时间" prop="Registe_Time"/>
        <el-table-column label="用户邮箱" prop="Email"/>
        <el-table-column label="用户权限" width="120px" prop="Role"/>
        <el-table-column label="到期时期" prop="LastDay"/>
        <el-table-column label="VNC时间" prop="VNCLastTime"/>
        <el-table-column label="Code" prop="Code"/>
        <el-table-column label="剩余点数" prop="LastNum"/>
        <el-table-column label="账户余额" prop="Money"/>
        <el-table-column label="卡密" prop="Code"/>
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button type="primary" plain @click="handleEdit(scope)"
            >修改
            </el-button
            >
            <el-popconfirm
                style="margin-left: 10px"
                confirm-button-text='删除'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                title="确认删除吗？"
                @confirm="deletePerson"
            >
              <el-button slot="reference" type="danger" @click="handleDelete(scope)">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <template>
      <el-pagination background layout="prev, pager, next"
                     @current-change="handleCurrentChange" :total="1000"/>
    </template>

    <!--修改用户点数-->
    <el-dialog
        title="修改"
        :visible.sync="dialogVisible"
        width="30%">
      <!--表单-->
      <el-form ref="form" :model="UpdatePoint" label-width="110px">
        <el-form-item label="邮箱：">
          <el-input v-model="UpdatePoint.email" disabled></el-input>
        </el-form-item>
        <el-form-item label="点数：">
          <div style="display: flex; align-items: center">
            <el-input v-model="UpdatePoint.num" style="flex: 0.8"></el-input>
            <el-button type="primary" @click="UpdatePointSure" style="flex: 0.2; margin-left: 20px">确 定</el-button>
          </div>
        </el-form-item>
        <el-form-item label="代理天数：">
          <div style="display: flex; align-items: center">
            <el-input v-model="UpdatePoint.day" style="flex: 0.8"></el-input>
            <el-button type="primary" @click="UpdateProxySure" style="flex: 0.2; margin-left: 20px">确 定</el-button>
          </div>
        </el-form-item>
        <el-form-item label="GPU天数：">
          <div style="display: flex; align-items: center">
            <el-input v-model="UpdatePoint.gpu" style="flex: 0.8"></el-input>
            <el-button type="primary" @click="UpdateGPUSure" style="flex: 0.2; margin-left: 20px">确 定</el-button>
          </div>
        </el-form-item>
        <el-form-item label="网络验证天数：">
          <div style="display: flex; align-items: center">
            <el-input v-model="UpdatePoint.web" style="flex: 0.8"></el-input>
            <el-button type="primary" @click="UpdateWebSure" style="flex: 0.2; margin-left: 20px">确 定</el-button>
          </div>
        </el-form-item>
        <el-form-item label="VNC时间">
          <div style="display: flex; align-items: center">
            <el-input v-model="UpdatePoint.VNCTime" style="flex: 0.8"></el-input>
            <el-button type="primary" @click="UpdateVNCTimeSure" style="flex: 0.2; margin-left: 20px">确 定</el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>

  </span>
    </el-dialog>


    <!--添加用户-->
    <el-dialog
        title="添加用户"
        :visible.sync="showAddUser"
        width="30%">
      <!--表单-->
      <el-form ref="form" label-width="80px">
        <el-form-item label="邮箱：">
          <el-input v-model="email"></el-input>
        </el-form-item>
        <el-form-item label="密码：">
          <el-input v-model="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addUserSure">确  定</el-button>
    <el-button @click="showAddUser = false">取 消</el-button>

  </span>
    </el-dialog>

  </div>

</template>
<script>
import {GetAllUser, SelectUserPoint, FindUser, DeletePerson, AddAgentDay, RegisterUser, addGPUDay, addWebDay,AddVNCDay} from "@/request/api.js";

export default {
  inject: ['reload'],
  name: "SetCombo",
  data() {
    return {
      TableData: [],

      dialogVisible: false,

      login: true,

      showAddUser: false,

      UpdatePoint: {
        email: '',
        num: '',
        gpu: '',
        web: '',
      },

      FindForm: {},

      deleteUid: '',

      email: '',
      password: '',
    }
  },
  methods: {
    UpdateGPUSure() {
      addGPUDay({
        email: this.UpdatePoint.email,
        day:this.UpdatePoint.gpu,
      }).then(res => {
        if (res.Code === 2000) {
          this.SuccessMessage();
          this.dialogVisible = false;
          this.reload();
        } else {
          this.ErrorMessage();
          this.dialogVisible = false;
        }
      })
    },
    UpdateWebSure() {
      addWebDay({
        email: this.UpdatePoint.email,
        day:this.UpdatePoint.web,
      }).then(res => {
        if (res.Code === 2000) {
          this.SuccessMessage();
          this.dialogVisible = false;
          this.reload();
        } else {
          this.ErrorMessage();
          this.dialogVisible = false;
        }
      })
    },
    UpdateVNCTimeSure() {
      AddVNCDay({
        email: this.UpdatePoint.email,
        day:this.UpdatePoint.VNCTime,
      }).then(res => {
        if (res.Code === 2000) {
          this.SuccessMessage();
          this.dialogVisible = false;
          this.reload();
        } else {
          this.ErrorMessage();
          this.dialogVisible = false;
        }
      })
    },
    handleEdit(scope) {
      this.dialogVisible = true;
      this.UpdatePoint.email = scope.row.Email;
      console.log(scope.row.Email)
    },
    handleDelete(scope) {
      this.deleteUid = scope.row.Id;
    },
    deletePerson() {
      console.log(this.deleteUid);
      DeletePerson({userid: this.deleteUid}).then(res => {
        if (res.Code === 2000) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        } else {
          this.$message({
            message: '删除失败',
            type: 'error'
          });
        }
        this.reload();
      });
    },
    allUser() {
      this.showAddUser = true;
    },
    addUserSure() {
      if (this.email === '') {
        this.$message({
          message: '请输入邮箱',
          type: 'error'
        });
        return
      }
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (!regEmail.test(this.email)) {
        this.$message({
          message: '请输入正确邮箱',
          type: 'error'
        });
        return
      }
      if (this.password === '') {
        this.$message({
          message: '请输入密码',
          type: 'error'
        });
        return
      }
      RegisterUser({
        email: this.email,
        password: this.password
      }).then(res => {
        console.log(res)
        if (res.Code === 2000) {
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          this.showAddUser = false;
        } else {
          this.$message({
            message: res.data.Message,
            type: 'error'
          });
        }
        this.reload();
      })
    },
    SuccessMessage() {
      this.$message({
        message: '修改成功',
        type: 'success'
      });
    },
    handleCurrentChange(val) {
      this.login = true;
      GetAllUser({
        pageIndex: val,
        pageSize: 10,
      }).then(res => {
        console.log(res.Data);
        this.TableData = res.Data;
        this.login = false;
      })
    },
    ErrorMessage() {
      this.$message({
        message: '修改失败',
        type: 'error'
      });
    },
    //确认修改
    UpdatePointSure() {
      SelectUserPoint({
        email: this.UpdatePoint.email,
        num: this.UpdatePoint.num
      }).then(res => {
        if (res.Code === 2000) {
          this.SuccessMessage();
          this.dialogVisible = false;
          this.reload();
        } else {
          this.ErrorMessage();
          this.dialogVisible = false;
        }
      })
    },
    UpdateProxySure() {
      AddAgentDay({
        email: this.UpdatePoint.email,
        day: this.UpdatePoint.day
      }).then(res => {
        if (res.Code === 2000) {
          this.SuccessMessage();
          this.dialogVisible = false;
          this.reload();
        } else {
          this.ErrorMessage();
          this.dialogVisible = false;
        }
      })
    },
    noInp() {
      if (this.FindForm.userName === '') {
        this.login = true;
        GetAllUser().then(res => {
          console.log(res.Data);
          this.TableData = res.Data;
          this.login = false;
        })
      }
    },
    findSure() {
      FindUser(this.FindForm).then(res => {
        this.TableData = [];
        console.log(res.Data);
        this.TableData[0] = res.Data;
      })
    }
  },
  created() {
    this.login = true;
    GetAllUser({
      pageIndex: 1,
      pageSize: 10,
    }).then(res => {
      console.log(res.Data);
      this.TableData = res.Data;
      this.login = false;
    })
  },
}
</script>

<style scoped>

</style>